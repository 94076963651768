@media (max-width: 768px) {
    .story-container {
      width: 100%;
      padding: 10px;
    }
    .control-panel {
      flex-direction: column;
      align-items: center;
    }
    .description-text {
      margin-top: 5px;
      margin-bottom: 15px;
      text-align: center;
    }
  }
  
  /* Desktop styles */
  .story-container {
      width: 70%; /* Default desktop width */
  }